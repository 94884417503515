import { I$W, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BlocksWidgetFacade } from '../../external/blocks-widget/blocks-widget.facade';
import { PostPageRenderModel } from '../../external/blocks-widget/blocks-widget.type';
import { AppData } from '../../viewer.app';
import { PostCategoriesApi } from '../Post Categories/viewer.controller';
import { PostCountersApi } from '../Post Counters New/viewer.controller';
import { PostMetadataApi } from '../Post Metadata/viewer.controller';
import { PostRatingsApi } from '../Post Ratings/viewer.controller';
import { PostTagsApi } from '../Post Tags/viewer.controller';
import { PostTitleApi } from '../Post Title/viewer.controller';
import blocksModel from './model';

export default blocksModel.createController(({ $w, flowAPI, appData }) => ({
  pageReady: async () => {
    const data = await (appData as AppData).fetchPostPageRenderModel();
    setPostPageHeader($w, flowAPI, data);
  },
  exports: blocksModel.getExports(),
}));

function setPostPageHeader(
  $w: I$W<Record<string, any>>,
  flowAPI: PlatformControllerFlowAPI,
  model: PostPageRenderModel,
) {
  const widgetData = PostPageRenderModel.toWidgetData(model);
  const blogFacade = new BlocksWidgetFacade(flowAPI);
  const ui = {
    categories: $w('#categories1') as PostCategoriesApi,
    title: $w('#title1') as PostTitleApi,
    ratings: $w('#ratings1') as PostRatingsApi,
    metadata: $w('#metadata1') as PostMetadataApi,
    counters: $w('#postCountersNew1') as PostCountersApi,
    tags: $w('#tags1') as PostTagsApi,
    heroImage: $w('#image') as $w.Image,
  };

  ui.heroImage.src = widgetData.heroImageURL;

  ui.categories.setCategories(widgetData.categories);
  ui.categories.onCategoryClick((slug) =>
    blogFacade.navigateToCategoryFeed(slug),
  );
  ui.title.setTitle(widgetData.title);
  ui.ratings.setRatings({
    average: widgetData.averageRating,
    total: widgetData.totalRatings,
  });
  ui.metadata.initialize({
    writer: widgetData.writer,
    firstPublishedDate: widgetData.firstPublishedDate,
    timeToRead: widgetData.timeToRead,
    lastPublishedDate: widgetData.lastPublishedDate,
  });
  ui.counters.initialize({
    id: widgetData.id,
    likeCount: widgetData.likeCount,
    viewCount: widgetData.viewCount,
    commentsCount: widgetData.commentCount,
    isLiked: widgetData.isLiked,
  });
  ui.tags.setTags(widgetData.tags);
  ui.tags.onTagClick((slug: string) => blogFacade.navigateToTagFeed(slug));
}

import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

const createController = (({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {},
    exports: {
      setTags: (tags: BlogTag[]) => {
        const formattedTags = tags.map((tag) => ({
          label: tag.label as string,
          value: tag.slug as string,
        }));
        const tagsElements = $w(selectors.tags) as $w.SelectionTags;

        if (formattedTags.length) {
          tagsElements.options = formattedTags;
        } else {
          tagsElements.delete();
        }
      },
      onTagClick: (callback: (slug: string) => Promise<void>) => {
        $w(selectors.tags).onClick(async (event: $w.MouseEvent) => {
          const tagsClicked = event.target.value;
          const lastTagClicked = tagsClicked.at(-1);
          if (!lastTagClicked) {
            throw new Error('No tag clicked');
          }
          await callback(lastTagClicked);
        });
      },
    },
  };
}) satisfies CreateController;

const selectors = {
  tags: '#tagsElement',
} as const;

export type PostTagsApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);

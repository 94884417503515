import model from './model';

type CreateController = Parameters<(typeof model)['createController']>[0];

type Rating = { average?: number; total?: number };

const createController = (({ $w }) => {
  const ui = {
    ratings: $w('#ratingsDisplay') as $w.RatingsDisplay,
  } as const;

  return {
    pageReady: async () => {},
    exports: {
      setRatings: (rating: Rating) => {
        if (rating.total === 0) {
          ui.ratings.delete();
        } else {
          // @ts-expect-error undefined used for empty state https://www.wix.com/velo/reference/$w/ratingsdisplay/rating
          ui.ratings.rating = rating.average || undefined;
          ui.ratings.numRatings = rating.total || 0;
        }
      },
    },
  };
}) satisfies CreateController;

export type PostRatingsApi = ReturnType<typeof createController>['exports'];

export default model.createController(createController);
